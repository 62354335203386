import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import slugify from 'slugify';

import ContentBox from './ContentBox';
import Table from './Table';


function renderCodeSnippet(snippets, group, id) {
  if (!snippets || !Array.isArray(snippets[group])) {
    return null;
  }

  for (const snippet of snippets[group]) {
    if (snippet.id && snippet.id === id) {
      let Component = null;

      switch (group) {
        case 'contentBox':
          Component = ContentBox;
          break;
        case 'table':
          Component = Table;
          break;
        default:
          continue;
      }

      if (Component) {
        return (
          <div key={`${group}|${id}`}>
            <Component {...snippet} />
          </div>
        );
      }
    }
  }

  return null;
}

function d2cOptions(references = [], snippets, generateTitleIds) {
  return {
    renderMark: {
      [MARKS.CODE]: (data) => {
        const output = [];

        if (data.indexOf('|') !== -1 || data.indexOf(':') !== -1) {
          const items = data.split('|');

          for (const item of items) {
            const [ group, id ] = item.split(':');

            if (id !== undefined) {
              output.push(renderCodeSnippet(snippets, group, id));
            }
          }
        }

        if (output.length > 0) {
          return output;
        }

        return null;
      }
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
        if (
          !data ||
          !data.target ||
          !data.target.sys ||
          !data.target.sys.id ||
          data.target.sys.linkType !== 'Asset'
        ) {
          return null;
        }

        const ref = references.find(ref => data.target.sys.id === ref.cid) || {};

        const alt = ref.alt || '';
        const href = ref.href || undefined;
        let src = undefined;

        if (ref.fixed && ref.fixed.src) {
          src = ref.fixed.src;
        }

        if (!src) {
          return null;
        }

        let className = 'responsiveImg';

        if (src.indexOf('-ar.') !== -1) {
          className += ' right';
        } else if (src.indexOf('-al.') !== -1) {
          className += ' left';
        }

        if (href) {
          return (
            <a href={href}>
              <img className={className} alt={alt} src={src} />
            </a>
          );
        }

        return (
          <img className={className} alt={alt} src={src} />
        );
      },
      [BLOCKS.PARAGRAPH]: ({ content }, children) => {
        if (
          Array.isArray(content) &&
          content.length === 1 &&
          content[0].hasOwnProperty('marks') &&
          Array.isArray(content[0].marks) &&
          content[0].marks.findIndex(m => m.type === 'code') !== -1
        ) {
          let className = 'grid';

          if (
            Array.isArray(children) &&
            children.length > 0 &&
            Array.isArray(children[0]) &&
            children[0].length > 0
          ) {
            className += ` c-${children[0].length}`;
          }

          return <div className={className}>{children}</div>;
        }

        return <p>{children}</p>
      },
      [BLOCKS.HEADING_2]: ({ content }, children) => {
        let id = undefined;

        if (generateTitleIds && content && content.length > 0 && content[0].value) {
          id = slugify(content[0].value);
        }

        return <h2 id={id}>{children}</h2>;
      }
    }
  };
};

function RichText({ data, snippets, generateTitleIds = false }) {
  return documentToReactComponents(JSON.parse(data.raw), d2cOptions(data.references, snippets, generateTitleIds));
}

export default RichText;