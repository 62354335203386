import React from "react";
import { Link } from "gatsby";


const ContentBox = ({ title, description, url, image, imageAlt }) => {
  return (
    <div className="contentBox">
      <div className="image">
        <img src={image} alt={imageAlt} />
      </div>
      <h4 className="title">{title}</h4>
      <p>{description}</p>
      <Link to={url} className="button">Read more</Link>
    </div>
  );
};

export default ContentBox;